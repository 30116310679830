import { Icon } from "@chakra-ui/react";
import React from "react";

export function CheckboxCircle(props) {
  return (
    <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/Icon" {...props}>
      <path
        d="M7.81545 12.9089L8.16903 13.2625L8.52258 12.9089L14.4142 7.01637L14.7678 6.66281L14.4142 6.30929L13.2359 5.13095L12.8823 4.77737L12.5288 5.13098L8.16897 9.49153L6.16506 7.48762L5.8115 7.13407L5.45795 7.48762L4.27962 8.66595L3.92606 9.01951L4.27962 9.37306L7.81545 12.9089ZM8.99984 17.0553C4.67348 17.0553 1.1665 13.5484 1.1665 9.22201C1.1665 4.89565 4.67348 1.38867 8.99984 1.38867C13.3262 1.38867 16.8332 4.89565 16.8332 9.22201C16.8332 13.5484 13.3262 17.0553 8.99984 17.0553Z"
        fill="#18753C"
        stroke="#18753C"
      />
    </Icon>
  );
}
