import { Icon } from "@chakra-ui/react";
import React from "react";

export function TechnicalError(props) {
  return (
    <Icon viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 13C67.4486 13 67 12.5514 67 12C67 11.4486 67.4486 11 68 11C68.5514 11 69 11.4486 69 12C69 12.5514 68.5514 13 68 13Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 76C40.4486 76 40 75.5514 40 75C40 74.4486 40.4486 74 41 74C41.5514 74 42 74.4486 42 75C42 75.5514 41.5514 76 41 76Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10C14.4486 10 14 9.5514 14 9C14 8.44855 14.4486 8 15 8C15.5514 8 16 8.44855 16 9C16 9.5514 15.5514 10 15 10Z"
        fill="#CACAFB"
      />
      <path
        d="M16 22C15.4486 22 15 21.5514 15 21C15 20.4486 15.4486 20 16 20C16.5514 20 17 20.4486 17 21C17 21.5514 16.5514 22 16 22Z"
        fill="#E1000F"
      />
      <path
        d="M20 22C19.4486 22 19 21.5514 19 21C19 20.4486 19.4486 20 20 20C20.5514 20 21 20.4486 21 21C21 21.5514 20.5514 22 20 22Z"
        fill="#E1000F"
      />
      <path
        d="M23 21C23 21.5514 23.4486 22 24 22C24.5514 22 25 21.5514 25 21C25 20.4486 24.5514 20 24 20C23.4486 20 23 20.4486 23 21Z"
        fill="#E1000F"
      />
      <path
        d="M62 20C62.5523 20 63 20.4477 63 21C63 21.5128 62.614 21.9355 62.1166 21.9933L62 22H32C31.4477 22 31 21.5523 31 21C31 20.4872 31.386 20.0645 31.8834 20.0067L32 20H62Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.2924 49.7066C51.1049 50.0982 51 50.5369 51 51C51 52.6569 52.3431 54 54 54C55.6569 54 57 52.6569 57 51C57 50.3849 56.8149 49.813 56.4973 49.337L60.8 43.6L60.869 43.495C61.1155 43.0635 61.0078 42.5059 60.6 42.2C60.1582 41.8686 59.5314 41.9582 59.2 42.4L54.8976 48.1366C54.6142 48.0478 54.3127 48 54 48C53.5369 48 53.0982 48.105 52.7066 48.2924L50.7071 46.2929L50.6129 46.2097C50.2206 45.9047 49.6534 45.9324 49.2929 46.2929C48.9024 46.6834 48.9024 47.3166 49.2929 47.7071L51.2924 49.7066ZM54 50C54.5523 50 55 50.4477 55 51C55 51.5523 54.5523 52 54 52C53.4477 52 53 51.5523 53 51C53 50.4477 53.4477 50 54 50Z"
        fill="#E1000F"
      />
      <path
        d="M66.9933 16.8834C66.9355 16.386 66.5128 16 66 16H12L11.8834 16.0067C11.386 16.0645 11 16.4872 11 17V63L11.0067 63.1166C11.0645 63.614 11.4872 64 12 64H36L36.1166 63.9933C36.614 63.9355 37 63.5128 37 63L36.9933 62.8834C36.9355 62.386 36.5128 62 36 62H13V26H50L50.1166 25.9933C50.614 25.9355 51 25.5128 51 25C51 24.4477 50.5523 24 50 24H13V18H65V33L65.0067 33.1166C65.0645 33.614 65.4872 34 66 34C66.5523 34 67 33.5523 67 33V17L66.9933 16.8834Z"
        fill="#000091"
      />
      <path
        d="M71 51C71 41.6112 63.3888 34 54 34C44.6112 34 37 41.6112 37 51C37 59.6209 43.417 66.743 51.7351 67.8504L51.2929 68.2929L51.2097 68.3871C50.9047 68.7794 50.9324 69.3466 51.2929 69.7071C51.6834 70.0976 52.3166 70.0976 52.7071 69.7071L54.7071 67.7071C54.7365 67.6777 54.7641 67.6465 54.7897 67.6136C54.8454 67.5419 54.8907 67.4636 54.9242 67.3826C54.9668 67.2797 54.9928 67.1682 54.9987 67.0515C54.9996 67.0344 55 67.0173 55 67C55 66.8652 54.9733 66.7367 54.925 66.6194C54.8762 66.5006 54.8036 66.3894 54.7071 66.2929L52.7071 64.2929L52.6129 64.2097C52.2206 63.9047 51.6534 63.9324 51.2929 64.2929L51.2097 64.3871C50.9047 64.7794 50.9324 65.3466 51.2929 65.7071L51.3529 65.7672C44.3311 64.5169 39 58.381 39 51C39 42.7157 45.7157 36 54 36C62.2843 36 69 42.7157 69 51C69 54.499 67.7995 57.8112 65.6355 60.4673C65.2867 60.8955 65.351 61.5254 65.7792 61.8742C66.2074 62.223 66.8372 62.1587 67.1861 61.7305C69.6381 58.7208 71 54.9635 71 51Z"
        fill="#000091"
      />
      <path
        d="M64.6262 64.2704C65.0571 63.9249 65.1264 63.2956 64.7809 62.8647C64.4354 62.4338 63.8061 62.3645 63.3752 62.71C62.7853 63.1829 62.1607 63.6108 61.5067 63.9896C61.0288 64.2664 60.8658 64.8782 61.1426 65.3561C61.4194 65.834 62.0312 65.997 62.5091 65.7202C63.2503 65.2909 63.958 64.8061 64.6262 64.2704Z"
        fill="#000091"
      />
      <path
        d="M56.2225 40.2248C55.4962 40.0757 54.7527 40 54 40C47.9249 40 43 44.9249 43 51C43 57.0751 47.9249 62 54 62C60.0751 62 65 57.0751 65 51C65 49.4841 64.6926 48.0095 64.1044 46.646C63.8856 46.1389 63.2972 45.9052 62.7901 46.124C62.283 46.3428 62.0493 46.9312 62.268 47.4383C62.7488 48.5527 63 49.7578 63 51C63 55.9706 58.9706 60 54 60C49.0294 60 45 55.9706 45 51C45 46.0294 49.0294 42 54 42C54.6174 42 55.2262 42.062 55.8203 42.184C56.3613 42.295 56.8899 41.9465 57.001 41.4055C57.1121 40.8645 56.7635 40.3359 56.2225 40.2248Z"
        fill="#000091"
      />
      <path
        d="M38 32C38.5523 32 39 32.4477 39 33C39 33.5128 38.614 33.9355 38.1166 33.9933L38 34H20C19.4477 34 19 33.5523 19 33C19 32.4872 19.386 32.0645 19.8834 32.0067L20 32H38Z"
        fill="#000091"
      />
      <path
        d="M35 39C35 38.4477 34.5523 38 34 38H20L19.8834 38.0067C19.386 38.0645 19 38.4872 19 39C19 39.5523 19.4477 40 20 40H34L34.1166 39.9933C34.614 39.9355 35 39.5128 35 39Z"
        fill="#000091"
      />
      <path
        d="M32 44C32.5523 44 33 44.4477 33 45C33 45.5128 32.614 45.9355 32.1166 45.9933L32 46H20C19.4477 46 19 45.5523 19 45C19 44.4872 19.386 44.0645 19.8834 44.0067L20 44H32Z"
        fill="#000091"
      />
    </Icon>
  );
}
