import { Icon } from "@chakra-ui/react";
import React from "react";

export function Notification(props) {
  return (
    <Icon viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75 62C74.4486 62 74 61.5514 74 61C74 60.4486 74.4486 60 75 60C75.5514 60 76 60.4486 76 61C76 61.5514 75.5514 62 75 62Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59 6C58.4486 6 58 5.5514 58 5C58 4.44855 58.4486 4 59 4C59.5514 4 60 4.44855 60 5C60 5.5514 59.5514 6 59 6Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 68C9.4486 68 9 67.5514 9 67C9 66.4486 9.4486 66 10 66C10.5514 66 11 66.4486 11 67C11 67.5514 10.5514 68 10 68Z"
        fill="#CACAFB"
      />
      <path
        d="M12 26C12 26.5514 12.4486 27 13 27C13.5514 27 14 26.5514 14 26C14 25.4486 13.5514 25 13 25C12.4486 25 12 25.4486 12 26Z"
        fill="#E1000F"
      />
      <path
        d="M16 27C15.4486 27 15 26.5514 15 26C15 25.4486 15.4486 25 16 25C16.5514 25 17 25.4486 17 26C17 26.5514 16.5514 27 16 27Z"
        fill="#E1000F"
      />
      <path
        d="M18 26C18 26.5514 18.4486 27 19 27C19.5514 27 20 26.5514 20 26C20 25.4486 19.5514 25 19 25C18.4486 25 18 25.4486 18 26Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 21H23C23.5523 21 24 21.4477 24 22V30C24 30.5523 23.5523 31 23 31H20V32C20 32.7043 19.2979 33.1753 18.6586 32.9402L18.5528 32.8944L14.764 31H9C8.48716 31 8.06449 30.614 8.00673 30.1166L8 30V22C8 21.4477 8.44772 21 9 21ZM19 29H22V23H10V29H15C15.1164 29 15.2317 29.0203 15.3406 29.0598L15.4472 29.1056L18 30.382V30C18 29.4872 18.386 29.0645 18.8834 29.0067L19 29Z"
        fill="#E1000F"
      />
      <path
        d="M51 29C50.4477 29 50 29.4477 50 30C50 30.5523 50.4477 31 51 31C51.5523 31 52 30.5523 52 30C52 29.4477 51.5523 29 51 29Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 25C48.2386 25 46 27.2386 46 30C46 32.7614 48.2386 35 51 35C53.7614 35 56 32.7614 56 30C56 27.2386 53.7614 25 51 25ZM51 27C52.6569 27 54 28.3431 54 30C54 31.6569 52.6569 33 51 33C49.3431 33 48 31.6569 48 30C48 28.3431 49.3431 27 51 27Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 21C44 17.1862 42.6883 15 40 15C37.3117 15 36 17.1862 36 21C36 21.1254 36.0231 21.2453 36.0652 21.3559C30.4748 22.4673 27.3058 26.2065 26.3229 32.9243L26.2309 33.6144C26.0477 35.1318 25.9692 36.7929 25.9929 38.6015L26.0002 39.0215L25.9959 39.888L26.0116 40.3616C26.0341 42.3322 25.2798 44.8561 24.1807 46.4266L20.2893 51.8431L20.1925 51.9946C20.0134 52.3038 19.9207 52.6483 19.9207 53C19.9207 54.1046 20.8161 55 21.9207 55L32 55C32 60.219 34.781 63 40 63C45.219 63 48 60.219 48 55L58.0793 55L58.2544 54.9923C58.6028 54.9617 58.9381 54.8402 59.2263 54.6385C60.1312 54.005 60.3512 52.758 59.7178 51.8531L55.8121 46.4165L55.6251 46.138C54.6477 44.6173 53.9984 42.5478 53.9985 40.8512L53.9996 40.0282L54.0141 39.3681C54.0208 38.9321 54.0217 38.5046 54.0168 38.0853C54.0104 37.533 53.5574 37.0906 53.0052 37.097C52.4529 37.1035 52.0105 37.5564 52.0169 38.1086C52.024 38.7106 52.0185 39.3316 52.0004 39.9718L51.9985 40.851L52.0056 41.2052C52.0907 43.3482 52.9324 45.7957 54.1816 47.5747L58.079 52.999L21.9207 53L25.8121 47.5835L25.9945 47.3125C27.3092 45.2808 28.1283 42.2947 27.9985 39.9448L27.9966 39.8967L27.9998 38.9784L27.9927 38.5755C27.8417 27.5074 31.4772 23 40 23C42.4335 23 44.4557 23.4211 46.0945 24.2589C46.5863 24.5103 47.1887 24.3154 47.4401 23.8237C47.6915 23.3319 47.4966 22.7295 47.0049 22.4781C46.0677 21.999 45.0398 21.6381 43.9199 21.3928C43.9715 21.2722 44 21.1394 44 21ZM42.0044 21.0943C42.0015 21.0633 42 21.0318 42 21C42 18.1471 41.3117 17 40 17C38.6883 17 38 18.1471 38 21C38 21.0284 37.9988 21.0565 37.9965 21.0842C38.6374 21.0279 39.3052 21 40 21C40.6939 21 41.362 21.0313 42.0044 21.0943ZM34 55C34 59.1144 35.8856 61 40 61C44.1144 61 46 59.1144 46 55H34Z"
        fill="#000091"
      />
      <path
        d="M40 68C43.5132 68 46.5351 66.8791 48.7071 64.7071C49.0976 64.3166 49.0976 63.6834 48.7071 63.2929C48.3166 62.9024 47.6834 62.9024 47.2929 63.2929C45.5167 65.0691 43.007 66 40 66C39.4477 66 39 66.4477 39 67C39 67.5523 39.4477 68 40 68Z"
        fill="#000091"
      />
      <path
        d="M51 62C50.4486 62 50 61.5514 50 61C50 60.4486 50.4486 60 51 60C51.5514 60 52 60.4486 52 61C52 61.5514 51.5514 62 51 62Z"
        fill="#000091"
      />
      <path
        d="M67 35C67.5523 35 68 35.4477 68 36C68 36.5128 67.614 36.9355 67.1166 36.9933L67 37H63C62.4477 37 62 36.5523 62 36C62 35.4872 62.386 35.0645 62.8834 35.0067L63 35H67Z"
        fill="#000091"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59 31H71C71.5523 31 72 31.4477 72 32V40L71.9933 40.1166C71.9355 40.614 71.5128 41 71 41H67.236L63.4472 42.8944L63.3414 42.9402C62.7021 43.1753 62 42.7043 62 42V41H59C58.4477 41 58 40.5523 58 40V32C58 31.4477 58.4477 31 59 31ZM60 33V39H63L63.1166 39.0067C63.614 39.0645 64 39.4872 64 40V40.382L66.5528 39.1056L66.6594 39.0598C66.7683 39.0203 66.8836 39 67 39H70V33H60Z"
        fill="#000091"
      />
    </Icon>
  );
}
