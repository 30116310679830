import { Icon, SystemProps } from "@chakra-ui/react";

export function InformationAvatar(props?: SystemProps) {
  return (
    <Icon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx="20" cy="20" r="19" fill="white" stroke="#0063CB" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3159 19.7361C28.3159 19.7361 31.2035 19.7921 30.6819 22.3856C30.1602 24.979 26.9746 23.4864 26.9746 23.4864L27.4031 19.8108L28.3159 19.7361Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3271 19.176C28.3083 19.1756 28.2895 19.1762 28.2708 19.1778L27.3579 19.2524C27.0925 19.2741 26.8792 19.4804 26.8483 19.7454L26.4198 23.421C26.3919 23.6604 26.5201 23.8908 26.7381 23.9929L26.8015 24.0216C26.9387 24.082 27.1374 24.1584 27.3815 24.2342C27.8819 24.3897 28.387 24.4806 28.8737 24.4726C30.076 24.4526 30.9639 23.8188 31.2301 22.4956C31.5299 21.0049 30.9097 19.9974 29.7524 19.4965C29.4395 19.361 29.1105 19.273 28.7824 19.2214C28.5846 19.1903 28.4269 19.1779 28.3271 19.176ZM27.9052 20.331L28.3285 20.2965L28.3769 20.2998C28.446 20.3052 28.5242 20.3142 28.609 20.3275C28.8503 20.3655 29.0906 20.4297 29.3089 20.5243C30.0088 20.8272 30.3228 21.3373 30.1342 22.2747C29.9794 23.0448 29.5634 23.3417 28.8552 23.3534C28.5029 23.3593 28.109 23.2883 27.7125 23.1652L27.6323 23.1396L27.5798 23.1217L27.9052 20.331Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0817 11.5826C19.3179 11.5266 14.1017 11.1908 13.5242 15.5568C12.9467 19.9227 13.5614 24.7365 14.0458 26.9381C14.5302 29.1398 24.5155 28.1136 25.0744 27.927C25.6333 27.7404 26.3226 27.0687 26.3226 27.0687L27.7012 20.3705C27.7012 20.3519 28.2601 12.2543 20.0817 11.5826Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2593 20.4076L28.2633 20.3286L28.2643 20.3023C28.2805 19.8521 28.2586 19.2755 28.169 18.6122C28.035 17.6208 27.7726 16.6411 27.3496 15.7198C26.1242 13.0512 23.7829 11.3241 20.1279 11.024C19.7088 10.9932 19.2988 10.9818 18.7901 11.0061C18.0235 11.0426 17.2735 11.158 16.5688 11.3758C14.5851 11.9888 13.2573 13.3153 12.9707 15.4825C12.4743 19.2348 12.7997 23.8723 13.5005 27.0578C13.7991 28.415 15.8882 28.875 19.3218 28.8869L19.6944 28.8864C21.9074 28.871 24.7559 28.6227 25.2517 28.4572C25.3697 28.4178 25.4897 28.3643 25.6126 28.2982C25.7783 28.2091 25.9477 28.0982 26.1197 27.9702C26.2665 27.861 26.4074 27.7451 26.5386 27.6293L26.6423 27.5358C26.6715 27.5088 26.6952 27.4863 26.7129 27.4691C26.793 27.391 26.8479 27.2907 26.8705 27.181L28.2491 20.4827C28.2542 20.458 28.2576 20.4329 28.2593 20.4076ZM20.0413 12.1403C23.2784 12.4062 25.2731 13.8775 26.3339 16.1878C26.707 17.0004 26.9412 17.8746 27.0612 18.7627C27.1369 19.323 27.1586 19.8113 27.1492 20.1921L27.1455 20.2987L25.8118 26.7785L25.7991 26.79C25.6899 26.8864 25.5725 26.9829 25.4529 27.0718C25.3216 27.1696 25.1964 27.2515 25.0838 27.3121C25.0136 27.3498 24.9508 27.3779 24.8981 27.3955L24.88 27.4004C24.729 27.4364 23.98 27.5299 23.136 27.6026C21.9626 27.7037 20.699 27.7658 19.5315 27.7679L19.2429 27.767C16.5164 27.7458 14.7099 27.3532 14.592 26.8172C13.9173 23.7507 13.6009 19.2411 14.0786 15.6298C14.3024 13.9374 15.3016 12.9392 16.8982 12.4458C17.5046 12.2584 18.164 12.1569 18.843 12.1246C19.3002 12.1028 19.6681 12.113 20.0413 12.1403Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.714 20.2952C26.211 20.7803 24.8883 23.5417 24.5716 24.8291C24.5716 24.8291 21.2742 23.747 16.5796 23.6723C16.5796 23.6723 14.0087 23.7283 12.9841 24.0268C12.9841 24.0268 13.2077 28.598 15.2942 30.9676C17.3807 33.3372 21.7772 32.6655 23.8451 31.2475C25.913 29.8295 26.2483 29.1391 26.7327 27.6278C27.217 26.0979 28.4652 20.4258 28.4652 20.4258L26.714 20.2952Z"
        fill="#968BB4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0555 21.266C28.0555 21.266 29.4154 19.1576 29.937 16.9746C30.4214 14.9223 29.4713 12.7579 27.5711 11.8437C25.9876 11.0787 24.7953 11.6758 24.7953 11.6758C24.7953 11.6758 26.4906 8.80244 23.957 6.24629C23.0628 5.35071 21.8332 4.84694 20.5664 4.88426C17.474 4.97755 16.5239 7.58967 16.5239 7.58967C16.5239 7.58967 13.2078 1.4885 6.42669 5.10815C5.73741 5.46266 4.9736 6.48885 4.60101 7.16054C2.36548 11.3026 9.74273 16.2097 13.599 16.5828C17.8279 17.012 20.3056 16.303 21.6656 15.2768C20.641 15.9111 21.7774 22.2362 28.0555 21.266Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0893 22.2728C15.3156 22.2728 15.4991 21.9888 15.4991 21.6384C15.4991 21.288 15.3156 21.004 15.0893 21.004C14.8629 21.004 14.6794 21.288 14.6794 21.6384C14.6794 21.9888 14.8629 22.2728 15.0893 22.2728Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8339 22.18C19.0603 22.18 19.2438 21.896 19.2438 21.5456C19.2438 21.1953 19.0603 20.9113 18.8339 20.9113C18.6076 20.9113 18.4241 21.1953 18.4241 21.5456C18.4241 21.896 18.6076 22.18 18.8339 22.18Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1188 17.7458C19.8318 17.6325 20.4487 17.8131 20.945 18.1807C21.0821 18.2823 21.2 18.3912 21.2996 18.5015C21.3631 18.5719 21.4091 18.6312 21.4382 18.674C21.6119 18.9295 21.5459 19.2777 21.2908 19.4516C21.0451 19.6192 20.7136 19.5641 20.5343 19.3316L20.5143 19.304C20.4862 19.2627 20.4059 19.1738 20.2804 19.0808C20.0258 18.8922 19.7233 18.7979 19.3531 18.8431L19.294 18.8514C18.9892 18.8999 18.7028 18.6917 18.6545 18.3864C18.6061 18.0811 18.814 17.7943 19.1188 17.7458Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5919 17.6917C14.8771 17.6226 15.1636 17.6248 15.4343 17.6677C15.5074 17.6793 15.565 17.6917 15.6037 17.7019C15.9023 17.7803 16.0808 18.0863 16.0025 18.3853C15.927 18.6737 15.6402 18.8503 15.3521 18.7922L15.3068 18.7818C15.2963 18.7797 15.2802 18.7767 15.2596 18.7735C15.1222 18.7517 14.9754 18.7505 14.8546 18.7798C14.7167 18.8132 14.6389 18.874 14.5912 19.0086L14.5814 19.0385C14.4927 19.3346 14.1811 19.5027 13.8855 19.4138C13.5899 19.325 13.4221 19.013 13.5108 18.7169C13.6786 18.1567 14.0776 17.8162 14.5919 17.6917Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3058 26.7595C19.3527 26.454 19.6381 26.2444 19.9431 26.2914C20.2482 26.3384 20.4575 26.6242 20.4105 26.9297C20.4019 26.9861 20.3843 27.0674 20.3547 27.1664C20.3063 27.328 20.239 27.4899 20.1474 27.645C19.8057 28.2238 19.2229 28.544 18.4533 28.4027C18.1497 28.347 17.9487 28.0553 18.0044 27.7512C18.058 27.458 18.3308 27.2603 18.6224 27.2966L18.6549 27.3016C18.9279 27.3517 19.0672 27.2752 19.1853 27.0751C19.2269 27.0047 19.26 26.925 19.284 26.845L19.2941 26.8094C19.2998 26.7882 19.3033 26.7727 19.3049 26.7644L19.3058 26.7595Z"
        fill="#2E186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1923 21.8537C16.2281 21.5467 16.5057 21.3269 16.8123 21.3627C17.1079 21.3973 17.3225 21.657 17.3054 21.9509L17.3025 21.9836L16.9317 25.1613L17.5553 24.997C17.8431 24.9212 18.1376 25.0845 18.2287 25.3643L18.238 25.3959C18.3137 25.6842 18.1507 25.9791 17.8713 26.0704L17.8397 26.0796L16.4239 26.4528C16.0537 26.5504 15.701 26.2554 15.7237 25.879L15.7266 25.8465L16.1923 21.8537Z"
        fill="#2E186A"
      />
    </Icon>
  );
}
