import { Icon } from "@chakra-ui/react";
import React from "react";

export function ErrorIcon(props) {
  return (
    <Icon viewBox="0 0 28 28" width="28px" height="28px" {...props}>
      <path
        d="M14.0001 27.3332C6.63608 27.3332 0.666748 21.3638 0.666748 13.9998C0.666748 6.63584 6.63608 0.666504 14.0001 0.666504C21.3641 0.666504 27.3334 6.63584 27.3334 13.9998C27.3334 21.3638 21.3641 27.3332 14.0001 27.3332ZM14.0001 12.1145L10.2294 8.3425L8.34275 10.2292L12.1147 13.9998L8.34275 17.7705L10.2294 19.6572L14.0001 15.8852L17.7707 19.6572L19.6574 17.7705L15.8854 13.9998L19.6574 10.2292L17.7707 8.3425L14.0001 12.1145Z"
        fill="currentColor"
      />
    </Icon>
  );
}
