import { Icon } from "@chakra-ui/react";
import React from "react";

export function Settings4Fill(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M5.33405 4.54507C6.36078 3.62535 7.56673 2.92806 8.87605 2.49707C9.25043 2.96609 9.72571 3.3447 10.2666 3.60474C10.8074 3.86478 11.3999 3.99956 12 3.99907C12.6002 3.99956 13.1927 3.86478 13.7335 3.60474C14.2744 3.3447 14.7497 2.96609 15.124 2.49707C16.4334 2.92806 17.6393 3.62535 18.666 4.54507C18.4476 5.10352 18.3577 5.70401 18.403 6.30196C18.4484 6.89991 18.6278 7.47997 18.928 7.99907C19.2276 8.51916 19.6406 8.96502 20.1363 9.30349C20.632 9.64196 21.1976 9.86433 21.791 9.95407C22.0716 11.303 22.0716 12.6952 21.791 14.0441C20.631 14.2221 19.561 14.9041 18.928 15.9991C18.6277 16.5183 18.4481 17.0985 18.4028 17.6967C18.3574 18.2948 18.4474 18.8955 18.666 19.4541C17.6392 20.3734 16.4333 21.0704 15.124 21.5011C14.7496 21.0322 14.2742 20.6538 13.7334 20.394C13.1925 20.1341 12.6001 19.9995 12 20.0001C11.3999 19.9996 10.8074 20.1344 10.2666 20.3944C9.72571 20.6544 9.25043 21.033 8.87605 21.5021C7.56682 21.0714 6.36087 20.3744 5.33405 19.4551C5.5527 18.8965 5.64269 18.2958 5.59733 17.6977C5.55197 17.0995 5.37243 16.5193 5.07205 16.0001C4.77234 15.4802 4.3593 15.0345 3.86363 14.6962C3.36797 14.3579 2.80242 14.1357 2.20905 14.0461C1.92836 12.6968 1.92836 11.3043 2.20905 9.95507C2.80251 9.86533 3.36811 9.64296 3.86379 9.30449C4.35946 8.96602 4.77245 8.52016 5.07205 8.00007C5.37228 7.48097 5.55173 6.90091 5.59709 6.30296C5.64245 5.70501 5.55254 5.10452 5.33405 4.54607V4.54507ZM13.5 14.5971C13.844 14.4015 14.1458 14.1399 14.3883 13.8272C14.6307 13.5145 14.8088 13.157 14.9125 12.7751C15.0161 12.3933 15.0433 11.9948 14.9923 11.6024C14.9413 11.2101 14.8132 10.8317 14.6153 10.4891C14.4175 10.1465 14.1539 9.84634 13.8396 9.60601C13.5253 9.36569 13.1666 9.1899 12.7841 9.08877C12.4016 8.98765 12.0029 8.96318 11.6109 9.01678C11.2189 9.07037 10.8414 9.20098 10.5 9.40107C9.81663 9.80172 9.31942 10.4564 9.11694 11.2223C8.91445 11.9882 9.02312 12.8031 9.41923 13.4892C9.81534 14.1752 10.4667 14.6768 11.2313 14.8843C11.9958 15.0919 12.8114 14.9886 13.5 14.5971V14.5971Z"
        fill="currentColor"
      />
    </Icon>
  );
}
