import React from "react";

export function Support() {
  return (
    <svg width="290" height="172" viewBox="0 0 290 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_8508_281178)">
        <path
          d="M109.726 107.716C107.515 106.532 105.639 107.103 103.556 109.296C104.508 103.969 104.476 97.5093 100.253 96.422C95.9031 95.2978 93.4386 98.0059 90.8892 104.551C90.9857 98.9347 89.8754 91.6909 85.2247 91.3966C79.9948 91.0656 78.266 95.3668 76.9671 104.907C75.3418 99.5714 70.8521 90.2265 65.5762 92.0794C60.4037 93.8932 60.737 98.8795 63.6106 109.045C60.7761 104.657 55.1048 98.1323 51.3093 100.613C47.6978 102.967 48.6863 106.804 52.6886 113.933C50.0472 112.783 47.3 112.67 45.1023 114.841C40.1689 119.714 42.5414 124.33 49.3874 132.898C56.2335 141.466 105.411 126.31 107.745 122.434C110.078 118.558 113.745 109.864 109.726 107.716Z"
          fill="white"
          stroke="#FEAD7C"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M110.113 122.273L110.152 122.147L44.0227 122.351L44.064 122.478C42.3297 122.816 40.7936 123.812 39.7782 125.259C38.7628 126.705 38.347 128.488 38.618 130.234L43.5675 162.138L110.608 161.933L115.559 130.029C115.83 128.283 115.414 126.501 114.398 125.054C113.383 123.608 111.847 122.612 110.113 122.273V122.273Z"
          fill="#FEBA92"
        />
        <path
          d="M218.441 110.772C217.415 110.772 216.389 110.832 215.37 110.951C209.88 103.365 203.579 96.9278 196.958 92.8404C179.717 82.2219 156.038 85.2541 133.939 93.4358C118.75 99.0566 99.4971 117.153 87.1521 129.988C71.0599 131.197 58.6896 142.151 59.4321 154.683C59.5956 157.374 60.349 159.997 61.6391 162.366L115.649 162.724C115.743 162.579 115.833 162.43 115.923 162.283L196.027 162.007C196.115 162.129 196.204 162.237 196.294 162.366L240.644 162.29C244.75 156.848 247.216 149.876 247.216 142.262C247.21 124.868 234.327 110.772 218.441 110.772Z"
          fill="#3C4A71"
        />
        <path
          d="M198.528 93.8613C178.147 106.494 159.82 106.772 147.974 104.74C136.98 102.855 129.063 98.6131 126.472 96.9648C126.032 97.2131 125.592 97.4675 125.15 97.728C127.263 99.1832 135.539 103.96 147.493 106.055C151.386 106.738 155.33 107.082 159.282 107.082C163.321 107.08 167.353 106.724 171.33 106.018C180.986 104.303 190.556 100.501 199.751 94.7188C199.338 94.4246 198.93 94.1387 198.528 93.8613Z"
          fill="white"
        />
        <path
          d="M196.416 115.606C199.657 111.054 203.666 108.496 208.335 107.999C210.027 107.818 211.737 107.924 213.393 108.314C212.962 107.758 212.527 107.21 212.087 106.67C210.82 106.506 209.538 106.489 208.266 106.62C204.756 106.978 199.657 108.689 195.301 114.806C195.195 114.955 195.153 115.14 195.184 115.32C195.214 115.5 195.315 115.661 195.464 115.767C195.581 115.85 195.721 115.895 195.864 115.896C195.972 115.894 196.079 115.867 196.174 115.817C196.27 115.766 196.353 115.694 196.416 115.606V115.606Z"
          fill="white"
        />
        <path
          d="M196.664 131.763H196.678C196.859 131.763 197.033 131.692 197.162 131.565C197.291 131.438 197.365 131.265 197.368 131.084C197.407 129.057 198.225 124.471 201.046 120.146C204.32 115.123 209.136 112.027 215.354 110.93C215.053 110.516 214.75 110.103 214.435 109.693C208.228 110.958 203.205 114.291 199.883 119.392C197.09 123.68 196.037 128.358 195.975 131.059C195.973 131.151 195.989 131.242 196.023 131.327C196.057 131.413 196.108 131.49 196.172 131.556C196.236 131.621 196.313 131.673 196.397 131.709C196.482 131.744 196.573 131.763 196.664 131.763V131.763Z"
          fill="white"
        />
        <path
          d="M99.2629 124.965C96.653 124.857 94.0394 125.057 91.4766 125.563C90.7823 126.259 90.1026 126.948 89.4375 127.632C91.987 126.232 106.516 124.487 112.516 131.31C112.581 131.383 112.661 131.441 112.75 131.481C112.839 131.52 112.936 131.54 113.033 131.54C113.202 131.541 113.366 131.479 113.493 131.368C113.63 131.247 113.714 131.077 113.725 130.894C113.737 130.712 113.676 130.532 113.555 130.395C109.691 125.997 102.941 125.108 99.2629 124.965Z"
          fill="white"
        />
        <path
          d="M111.085 134.839C102.961 128.71 92.8569 128.344 88.5718 128.507L87.1465 129.981C89.6017 129.781 101.365 129.232 110.253 135.94C110.399 136.05 110.582 136.098 110.764 136.073C110.945 136.047 111.109 135.951 111.219 135.805C111.33 135.659 111.377 135.475 111.352 135.294C111.327 135.113 111.231 134.949 111.085 134.839Z"
          fill="white"
        />
        <path
          d="M76.0352 162.138C76.0352 162.138 83.5686 139.839 104.787 143.887C126.006 147.935 155.032 156.632 155.032 156.632V162.22L76.0352 162.138Z"
          fill="white"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M243.563 79.7823C243.045 79.4955 242.602 79.0917 242.269 78.6031C241.936 78.1145 241.721 77.5547 241.643 76.9684C241.413 74.996 242.737 66.4993 244.861 65.3131C246.986 64.1269 256.714 58.8509 258.51 58.9728C260.305 59.0946 261.062 74.6971 243.563 79.7823Z"
          fill="white"
        />
        <path
          d="M244.596 66.263C248.228 53.5479 251.203 50.7088 252.915 51.9456C254.467 53.0674 251.306 61.1687 250.387 65.3733"
          fill="white"
        />
        <path
          d="M244.596 66.263C248.228 53.5479 251.203 50.7088 252.915 51.9456C254.467 53.0674 251.306 61.1687 250.387 65.3733"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M260.625 63.4924C262.446 60.536 265.453 60.6831 263.883 65.7223C262.352 70.635 261.216 77.042 258.343 79.9363C255.469 82.8306 254.435 84.1271 254.435 84.1271C254.435 84.1271 248.687 105.576 245.425 123.408C240.703 149.197 231.8 154.158 223.475 151.204C215.151 148.25 213.613 136.592 224.981 113.932C236.349 91.2721 242.342 78.0696 242.342 78.0696"
          fill="white"
        />
        <path
          d="M260.625 63.4924C262.446 60.536 265.453 60.6831 263.883 65.7223C262.352 70.635 261.216 77.042 258.343 79.9363C255.469 82.8306 254.435 84.1271 254.435 84.1271C254.435 84.1271 248.687 105.576 245.425 123.408C240.703 149.197 231.8 154.158 223.475 151.204C215.151 148.25 213.613 136.592 224.981 113.932C236.349 91.2721 242.342 78.0696 242.342 78.0696"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M242.533 78.4767C240.885 77.3617 241.083 70.8765 239.232 68.0512C236.933 64.5293 234.685 60.8787 238.94 61.4258C242.588 61.8971 243.749 65.1041 244.595 66.2535"
          fill="white"
        />
        <path
          d="M242.533 78.4767C240.885 77.3617 241.083 70.8765 239.232 68.0512C236.933 64.5293 234.685 60.8787 238.94 61.4258C242.588 61.8971 243.749 65.1041 244.595 66.2535"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M258.5 68.886C258.5 68.886 261.732 61.0124 262.817 57.3709C264.005 53.3847 259.828 52.8237 257.814 58.5319"
          fill="white"
        />
        <path
          d="M258.5 68.886C258.5 68.886 261.732 61.0124 262.817 57.3709C264.005 53.3847 259.828 52.8237 257.814 58.5319"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M254.703 66.8193C254.703 66.8193 261.066 52.4007 258.22 51.1179C255.625 49.9478 253.646 55.2076 250.379 65.371"
          fill="white"
        />
        <path
          d="M254.703 66.8193C254.703 66.8193 261.066 52.4007 258.22 51.1179C255.625 49.9478 253.646 55.2076 250.379 65.371"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.625 77.2892L151.517 93.5469C151.517 93.5469 150.007 102.319 162.193 100.694C174.331 99.0734 172.841 91.8871 172.841 91.8871L169.085 71.6914L150.625 77.2892Z"
          fill="white"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.75 51.2098C173.75 51.2098 183.175 47.6741 183.543 57.4903C183.911 67.3066 173.75 64.764 173.75 64.764"
          fill="white"
        />
        <path
          d="M173.75 51.2098C173.75 51.2098 183.175 47.6741 183.543 57.4903C183.911 67.3066 173.75 64.764 173.75 64.764"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.429 35.7734H165.609C167.986 35.7734 170.267 36.718 171.948 38.3994C173.63 40.0808 174.574 42.3612 174.574 44.7391V63.7371C174.574 68.6415 172.626 73.3451 169.158 76.813C165.69 80.281 160.987 82.2293 156.082 82.2293V82.2293C153.654 82.2293 151.249 81.751 149.005 80.8216C146.762 79.8923 144.723 78.5302 143.006 76.813C141.289 75.0959 139.927 73.0573 138.997 70.8137C138.068 68.5701 137.59 66.1655 137.59 63.7371V47.6104C137.59 44.471 138.837 41.4603 141.057 39.2404C143.277 37.0205 146.287 35.7734 149.427 35.7734H149.429Z"
          fill="white"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.878 72.5024C178.884 68.4772 178.506 64.4606 177.751 60.5069C177.751 60.431 177.751 60.3505 177.751 60.277C177.748 59.5829 177.471 58.918 176.98 58.4272C176.49 57.9364 175.825 57.6593 175.131 57.6562V60.277L175.2 62.8977C175.731 62.8815 176.245 62.7034 176.673 62.3874C177.522 67.6748 179.202 83.4405 172.128 96.843C170.829 99.3005 169.611 101.572 168.434 103.767C161.767 116.209 157.353 124.427 157.457 131.768C157.457 131.951 157.53 132.126 157.659 132.255C157.788 132.385 157.964 132.457 158.147 132.457V132.457C158.329 132.457 158.505 132.385 158.634 132.255C158.764 132.126 158.836 131.951 158.836 131.768C158.737 124.786 163.08 116.692 169.641 104.439C170.82 102.243 172.039 99.9695 173.338 97.5074C177.981 88.7142 178.912 79.0589 178.878 72.5024Z"
          fill="#FEAD7C"
        />
        <path
          d="M135.792 63.1739C136.115 63.3559 136.473 63.4665 136.842 63.498V58.2842C136.258 58.3375 135.708 58.5856 135.281 58.9887C134.854 59.3918 134.575 59.9265 134.488 60.5072C134.412 60.6207 134.371 60.7543 134.371 60.8911C134.371 61.9394 134.371 86.7076 141.728 97.8825C149.249 109.331 151.576 115.851 152.093 126.952C152.101 127.129 152.178 127.296 152.306 127.419C152.435 127.541 152.605 127.61 152.783 127.609H152.815C152.998 127.601 153.169 127.52 153.293 127.386C153.416 127.251 153.481 127.072 153.473 126.89C152.941 115.497 150.567 108.821 142.884 97.1284C137.024 88.2225 135.978 69.643 135.792 63.1739Z"
          fill="#FEAD7C"
        />
        <path
          d="M150.426 52.6445L150.669 67.5528C150.669 67.5528 151.768 69.139 153.536 65.9068"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.426 70.7232C151.768 72.2934 154.922 72.0359 158.049 69.9922C158.049 69.9922 159.267 73.9853 153.201 74.59"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.295 47.842C156.479 47.796 158.444 44.9615 161.463 47.3822"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.463 58.8033C162.094 58.8033 162.605 58.0118 162.605 57.0354C162.605 56.0591 162.094 55.2676 161.463 55.2676C160.832 55.2676 160.32 56.0591 160.32 57.0354C160.32 58.0118 160.832 58.8033 161.463 58.8033Z"
          fill="#2E186A"
        />
        <path
          d="M144.75 59.2583C145.381 59.2583 145.893 58.4669 145.893 57.4905C145.893 56.5141 145.381 55.7227 144.75 55.7227C144.119 55.7227 143.607 56.5141 143.607 57.4905C143.607 58.4669 144.119 59.2583 144.75 59.2583Z"
          fill="#2E186A"
        />
        <path
          d="M141.098 47.7108C141.282 47.6717 143.771 44.2625 146.279 47.4809"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M160.691 31.7546C156.215 31.8121 152.817 32.0373 150.78 32.4098C134.327 36.1524 136.987 48.3456 136.987 48.3456C136.987 48.3456 145.033 49.879 151.583 40.1823C151.583 40.1823 160.578 47.5226 173.882 50.479C174.095 50.4284 174.341 50.3618 174.571 50.2882V50.9618C175.667 50.6705 176.802 50.5519 177.935 50.61C177.787 45.8031 176.928 39.7455 172.967 35.8351C170.164 33.0581 166.026 31.6833 160.691 31.7546Z"
          fill="#273962"
        />
        <path
          d="M178.629 49.438C178.105 43.3736 176.656 31.201 161.463 31.0469C158.052 31.0126 154.642 31.2015 151.256 31.6125"
          stroke="white"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M203.881 21.7454C198.21 5.51758 182.104 -3.61129 167.915 1.352C156.253 5.43022 149.524 17.7614 150.637 31.0329C152.773 30.6559 156.141 30.4329 160.672 30.3754C166.389 30.3018 170.851 31.8076 173.939 34.8536C178.306 39.1732 179.194 45.7388 179.323 50.8378C181.435 51.4102 183.38 53.1367 183.543 57.4907C183.559 57.9321 183.173 61.3069 183.267 61.2954C183.253 62.6276 183.615 63.9368 184.311 65.0725C185.893 67.6242 189.118 69.2105 193.902 69.7668H193.982C194.165 69.7775 194.345 69.715 194.482 69.5932C194.619 69.4714 194.702 69.3003 194.712 69.1174C194.723 68.9344 194.661 68.7548 194.539 68.6179C194.417 68.481 194.246 68.3981 194.063 68.3875C189.73 67.8771 186.847 66.5208 185.495 64.3529C185.017 63.5784 184.734 62.6997 184.67 61.792C185.851 63.1713 187.888 64.6587 191.134 64.6587C191.99 64.654 192.843 64.5608 193.679 64.3805C193.771 64.3652 193.858 64.3315 193.936 64.2815C194.015 64.2316 194.082 64.1663 194.134 64.0897C194.187 64.0131 194.223 63.9267 194.241 63.8356C194.26 63.7446 194.259 63.6509 194.24 63.56C194.221 63.4691 194.184 63.3829 194.131 63.3067C194.078 63.2305 194.011 63.1657 193.932 63.1163C193.853 63.067 193.766 63.0339 193.674 63.0193C193.582 63.0046 193.489 63.0086 193.399 63.0311C189.217 63.9 186.881 62.3069 185.679 60.8862C186.621 60.6797 187.55 60.4164 188.461 60.0977C202.649 55.1459 209.553 37.9709 203.881 21.7454Z"
          fill="#273962"
        />
        <path
          d="M162.471 65.905C167.117 65.905 170.884 62.1379 170.884 57.4911C170.884 52.8442 167.117 49.0771 162.471 49.0771C157.824 49.0771 154.057 52.8442 154.057 57.4911C154.057 62.1379 157.824 65.905 162.471 65.905Z"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.383 57.2637H153.873"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.826 53.4064L174.573 50.7236"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.695 65.905C144.342 65.905 148.109 62.1379 148.109 57.4911C148.109 52.8442 144.342 49.0771 139.695 49.0771C135.048 49.0771 131.281 52.8442 131.281 57.4911C131.281 62.1379 135.048 65.905 139.695 65.905Z"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M197.558 153.539H192.779L190.429 118.763C190.334 117.571 189.797 116.456 188.924 115.638C188.05 114.821 186.904 114.358 185.707 114.34H119.088C118.518 114.332 117.953 114.443 117.429 114.667C116.905 114.89 116.434 115.221 116.045 115.638C115.657 116.054 115.36 116.548 115.174 117.086C114.987 117.624 114.916 118.196 114.964 118.763L117.564 157.263C117.659 158.456 118.195 159.57 119.069 160.388C119.942 161.206 121.089 161.669 122.286 161.686L197.558 161.589C197.885 161.589 198.209 161.525 198.512 161.4C198.814 161.274 199.089 161.091 199.32 160.859C199.551 160.628 199.735 160.353 199.86 160.051C199.985 159.749 200.05 159.424 200.05 159.097V156.031C200.05 155.703 199.985 155.379 199.86 155.077C199.735 154.775 199.551 154.5 199.32 154.268C199.089 154.037 198.814 153.853 198.512 153.728C198.209 153.603 197.885 153.539 197.558 153.539V153.539Z"
          fill="#FEAD7C"
        />
        <path
          d="M157.111 137.336C156.156 136.346 154.917 135.675 153.566 135.416C153.785 135.102 153.918 134.736 153.952 134.354C154.005 133.756 153.831 133.16 153.464 132.684C153.097 132.209 152.565 131.889 151.973 131.789C151.947 131.789 151.92 131.789 151.895 131.789C151.637 130.839 151.152 129.285 150.596 128.621C150.494 128.497 150.353 128.411 150.196 128.377C150.039 128.343 149.876 128.362 149.731 128.432C149.668 128.468 149.615 128.517 149.575 128.577C149.534 128.637 149.509 128.706 149.5 128.777C149.492 128.849 149.5 128.922 149.524 128.99C149.549 129.057 149.589 129.118 149.642 129.168C150.007 129.605 150.453 130.966 150.731 132.002C150.412 132.172 150.142 132.42 149.945 132.723C149.748 133.025 149.631 133.373 149.605 133.733C149.578 134.071 149.622 134.41 149.735 134.729C149.847 135.048 150.025 135.34 150.258 135.586C149.849 135.716 149.458 135.894 149.092 136.117C146.697 137.605 146.403 140.566 148.435 142.729C150.467 144.892 154.058 145.442 156.453 143.954C158.849 142.467 159.145 139.499 157.111 137.336Z"
          fill="white"
        />
        <path
          d="M156.101 128.508C156.101 128.508 152.653 127.818 152.193 130.69C152.202 130.694 154.913 131.667 156.101 128.508Z"
          fill="white"
        />
        <path
          d="M4.875 162.361H285.125"
          stroke="#2E186A"
          strokeWidth="1.37933"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8508_281178"
          x="0.185303"
          y="0"
          width="289.629"
          height="171.051"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8508_281178" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8508_281178" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
