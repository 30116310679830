const rootFontSizePx = 16;

const colors = {
  // doc https://www.systeme-de-design.gouv.fr/elements-d-interface/fondamentaux-identite-de-l-etat/couleurs-palette/
  bluefrance: "#000091", // TODO replace by bluefrances.sun
  bluefrances: {
    main: "#000091",
    113: "#000091",
    sun: "#000091",
    525: "#6A6AF4",
    900: "#000091",
    925: "#E3E3FD",
  },
  white: "#ffffff",
  redmarianne: "#e1000f",
  openbluefrance: "#E3E3FD", // TODO replace by bluefrances.925
  bluefrance_light: "#5770BE",
  bluefrance_hover: "#1414B2",
  blue_cumulus_main: "#3558A2",
  success: "#0d6635",
  error: "#b60000",
  warning: "#fa5c00",
  warning2: "#E4794A",
  info: "#3a55d1",
  galt: "#f9f8f6",
  galt_hover: "#eceae3",
  galt2: "#F6F6F6",
  galtDark: "#ECEAE3",
  mgalt: "#666666",
  dgalt: "#E5E5E5",
  disablegrey: "#929292",
  labelgrey: "#161616",
  flatwarm: "#B34000",
  plaininfo: "#0063CB",
  greensoftc: "#22967E",
  flatsuccess: "#18753C",
  flaterror: "#CE0500",
  // hack so we can use bluefrance color in Progress component which only accepts a colorScheme prop
  main: {
    900: "#000091",
    800: "#000091",
    700: "#000091",
    600: "#000091",
    500: "#000091",
    400: "#000091",
    300: "#000091",
    200: "#000091",
    100: "#000091",
  },
  dsfr_lightprimary: {
    bluefrance_850: "#8B8BF6",
  },
  red_tag: {
    900: "#7f0000",
    800: "#B60000",
    700: "#d10000",
    600: "#e60000",
    500: "#ff0000",
    400: "#ff4d4d",
    300: "#ff9999",
    200: "#ffcccc",
    100: "#F6D0CD",
    50: "#ffebeb",
  },
  redlight_tag: {
    900: "#A5231A",
    800: "#E63122",
    700: "#F14B39",
    600: "#F56650",
    500: "#F88F7B",
    400: "#FAA8A0",
    300: "#FCC1C2",
    200: "#FDDDDD",
    100: "#FEEAEA",
    50: "#FFF5F5",
  },
  blue_tag: {
    900: "#0D1B5E",
    800: "#15287A",
    700: "#1D3596",
    600: "#2542B2",
    500: "#2D4FCE",
    400: "#355CEB",
    300: "#4A72FF",
    200: "#9BB3FF",
    100: "#e1e0eb",
    50: "#F3F3FF",
  },
  bluelight_tag: {
    900: "#000061",
    800: "#000091",
    700: "#0000C1",
    600: "#3333D1",
    500: "#6666E1",
    400: "#9999F1",
    300: "#CCCCFF",
    200: "#E0E0FD",
    100: "#E3E3FD",
    50: "#F7F7FF",
  },
  green_tag: {
    900: "#004D40",
    800: "#22967e",
    700: "#00796B",
    600: "#00897B",
    500: "#009688",
    400: "#26A69A",
    300: "#4DB6AC",
    200: "#80CBC4",
    100: "#e5f7f4",
    50: "#E0F2F1",
  },
  orange_tag: {
    900: "#BF360C",
    800: "#FF732C",
    700: "#E64A19",
    600: "#F4511E",
    500: "#FF5722",
    400: "#FF7043",
    300: "#FF8A65",
    200: "#FFAB91",
    100: "#FF732C1A",
    50: "#FBE9E7",
  },
  grey_tag: {
    900: "#12161F",
    800: "#1A202C",
    700: "#2D3748",
    600: "#4A5568",
    500: "#718096",
    400: "#A0AEC0",
    300: "#CBD5E0",
    200: "#E2E8F0",
    100: "#ECEAE3",
    50: "#F7FAFC",
  },
  grey: {
    800: "#1e1e1e",
    750: "#2a2a2a",
    700: "#383838",
    600: "#6a6a6a",
    500: "#9c9c9c",
    400: "#cecece",
    300: "#e7e7e7",
    200: "#f0f0f0",
    100: "#f8f8f8",
  },
  greenwarm: {
    600: "#877e59",
    500: "#958b62",
    400: "#cac5b1",
    300: "#e5e2d8",
    200: "#f4f3ef",
  },
  greenlight: {
    600: "#88a34a",
    500: "#91ae4f",
    400: "#c8d7a7",
    300: "#e3ebd3",
    200: "#f4f7ed",
  },
  greenmedium: {
    600: "#19905b",
    500: "#169b62",
    400: "#8bcdb1",
    300: "#c5e6d8",
    200: "#e8f5ef",
  },
  greendark: {
    600: "#40605b",
    500: "#466964",
    400: "#a3b4b2",
    300: "#d1dad8",
    200: "#edf0f0",
  },
  greensoft: {
    600: "#22967e",
    500: "#00ac8c",
    400: "#80d6c6",
    300: "#bfeae2",
    200: "#e5f7f4",
  },
  bluesoft: {
    600: "#5266a1",
    500: "#5770be",
    400: "#abb8de",
    300: "#d5dbef",
    200: "#eef1f8",
  },
  bluedark: {
    600: "#444871",
    500: "#484d7a",
    400: "#a4a6bc",
    300: "#d5dbef",
    200: "#eef1f8",
  },
  pinksoft: {
    600: "#d07c75",
    500: "#ff8d7e",
    400: "#ffc6bf",
    300: "#ffe2df",
    200: "#fff4f2",
  },
  pinkdark: {
    600: "#c0806f",
    500: "#d08a77",
    400: "#e7c4bb",
    300: "#f3e2dd",
    200: "#faf3f1",
  },
  pinklight: {
    600: "#ddb094",
    500: "#ffc29e",
    400: "#ffe0cf",
    300: "#fff0e7",
    200: "#fff9f5",
  },
  yellowmedium: {
    600: "#ead737",
    500: "#ffe800",
    400: "#fff480",
    300: "#fff9bf",
    200: "#fffde5",
  },
  yellowdark: {
    600: "#e3bd41",
    500: "#fdcf41",
    400: "#fff480",
    300: "#fff9bf",
    200: "#fffde5",
  },
  orangemedium: {
    600: "#d38742",
    500: "#ff9940",
    400: "#ffcc9f",
    300: "#ffe5cf",
    200: "#fff5ec",
  },
  orangedark: {
    600: "#d0805b",
    500: "#e18b63",
    400: "#f0c5b1",
    300: "#f8e2d8",
    200: "#fcf3ef",
  },
  orangesoft: {
    600: "#cb634b",
    500: "#ff6f4c",
    400: "#ffb7a5",
    300: "#ffdbd2",
    200: "#fff1ed",
  },
  purple: {
    600: "#714753",
    500: "#7d4e5b",
    400: "#bea6ad",
    300: "#ded3d6",
    200: "#f2edef",
  },
  brown: {
    600: "#956052",
    500: "#a26959",
    400: "#d0b4ac",
    300: "#e8dad6",
    200: "#f6f0ee",
  },
  bluegrey: {
    200: "#eef1f8",
    100: "#f9fafc",
  },
  purpleglycine: {
    950: "#fee7fc",
  },
  purpleglycinesun: {
    319: "#6e445a",
  },
};

// const fontSizes = {
//   giga: `${76 / rootFontSizePx}rem`,
//   mega: `${54 / rootFontSizePx}rem`,
//   alpha: `${32 / rootFontSizePx}rem`,
//   beta: `${24 / rootFontSizePx}rem`,
//   gamma: `${20 / rootFontSizePx}rem`,
//   delta: `${18 / rootFontSizePx}rem`,
//   epsilon: `${16 / rootFontSizePx}rem`,
//   zeta: `${14 / rootFontSizePx}rem`,
//   omega: `${12 / rootFontSizePx}rem`,
//   caption: `${11 / rootFontSizePx}rem`,
//   legal: `${10 / rootFontSizePx}rem`,
// };

const fontSizes = {
  giga: "4.75rem",
  mega: "3.375rem",
  alpha: "2.25rem",
  beta: "1.75rem",
  gamma: "1.25rem",
  delta: "1.125rem",
  epsilon: "1rem",
  zeta: "0.875rem",
  omega: "0.75rem",
  caption: "0.6875rem",
  legal: "0.625rem",
  mini: "0.525rem",
};

const textStyles = {
  h2: {
    fontSize: ["1.75rem", "2rem"],
    lineHeight: ["2.25rem", "2.5rem"],
    fontWeight: "700",
  },
  h4: {
    fontSize: ["1.375rem", "1.5rem"],
    lineHeight: ["1.75rem", "2rem"],
    fontWeight: "700",
  },
  h6: {
    fontSize: ["1.125rem", "1.25rem"],
    lineHeight: ["1.5rem", "1.75rem"],
    fontWeight: "700",
  },
  "rf-text": {
    fontSize: ["1rem"],
    lineHeight: "1.5rem",
    fontWeight: "400",
  },
  sm: {
    fontSize: ["0.875rem"],
    lineHeight: "1.5rem",
    fontWeight: "400",
  },
  xs: {
    fontSize: ["0.75rem"],
    lineHeight: "1.35rem",
    fontWeight: "400",
  },
};

const fonts = {
  body: "Marianne, Arial",
  heading: "Marianne, Arial",
};

const space = {
  0: "0",
  "1v": `${4 / rootFontSizePx}rem`,
  "1w": `${8 / rootFontSizePx}rem`,
  "3v": `${12 / rootFontSizePx}rem`,
  "2w": `${16 / rootFontSizePx}rem`,
  "3w": `${24 / rootFontSizePx}rem`,
  "4w": `${32 / rootFontSizePx}rem`,
  "5w": `${40 / rootFontSizePx}rem`,
  "6w": `${48 / rootFontSizePx}rem`,
  "7w": `${56 / rootFontSizePx}rem`,
  "8w": `${64 / rootFontSizePx}rem`,
  "9w": `${72 / rootFontSizePx}rem`,
  "12w": `${96 / rootFontSizePx}rem`,
  "15w": `${120 / rootFontSizePx}rem`,
};

export { fonts, colors, fontSizes, space, rootFontSizePx, textStyles };
