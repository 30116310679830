import { Icon } from "@chakra-ui/react";
import React from "react";

export function DataVisualisation(props) {
  return (
    <Icon viewBox="0 0 80 80" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 18C72.4486 18 72 17.5514 72 17C72 16.4486 72.4486 16 73 16C73.5514 16 74 16.4486 74 17C74 17.5514 73.5514 18 73 18Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C6.4486 18 6 17.5514 6 17C6 16.4486 6.4486 16 7 16C7.5514 16 8 16.4486 8 17C8 17.5514 7.5514 18 7 18Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 68C10.4486 68 10 67.5514 10 67C10 66.4486 10.4486 66 11 66C11.5514 66 12 66.4486 12 67C12 67.5514 11.5514 68 11 68Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65 72C64.4486 72 64 71.5514 64 71C64 70.4486 64.4486 70 65 70C65.5514 70 66 70.4486 66 71C66 71.5514 65.5514 72 65 72Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6C18.4486 6 18 5.5514 18 5C18 4.44855 18.4486 4 19 4C19.5514 4 20 4.44855 20 5C20 5.5514 19.5514 6 19 6Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 78C54.4486 78 54 77.5514 54 77C54 76.4486 54.4486 76 55 76C55.5514 76 56 76.4486 56 77C56 77.5514 55.5514 78 55 78Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30C16 27.7934 17.7935 26 20 26C22.2065 26 24 27.7934 24 30C24 32.2065 22.2065 34 20 34C17.7935 34 16 32.2065 16 30ZM22 30C22 28.898 21.102 28 20 28C18.898 28 18 28.898 18 30C18 31.1019 18.8981 32 20 32C21.1019 32 22 31.1019 22 30Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 26C26 23.7934 27.7935 22 30 22C32.2065 22 34 23.7934 34 26C34 28.2065 32.2065 30 30 30C27.7935 30 26 28.2065 26 26ZM32 26C32 24.898 31.102 24 30 24C28.898 24 28 24.898 28 26C28 27.1019 28.8981 28 30 28C31.1019 28 32 27.1019 32 26Z"
        fill="#E1000F"
      />
      <path
        d="M26.5528 26.1056C27.0468 25.8586 27.6474 26.0588 27.8944 26.5528C28.1238 27.0115 27.9675 27.5622 27.5485 27.8363L27.4472 27.8944L23.4472 29.8944C22.9532 30.1414 22.3526 29.9412 22.1056 29.4472C21.8762 28.9885 22.0325 28.4378 22.4515 28.1637L22.5528 28.1056L26.5528 26.1056Z"
        fill="#E1000F"
      />
      <path
        d="M32.2929 27.2929C32.6534 26.9324 33.2206 26.9047 33.6129 27.2097L33.7071 27.2929L37.7071 31.2929C38.0976 31.6834 38.0976 32.3166 37.7071 32.7071C37.3466 33.0676 36.7794 33.0953 36.3871 32.7903L36.2929 32.7071L32.2929 28.7071C31.9024 28.3166 31.9024 27.6834 32.2929 27.2929Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 34C50 28.4848 54.485 24 60 24C65.515 24 70 28.4848 70 34C70 39.5149 65.5149 44 60 44C54.4851 44 50 39.5149 50 34ZM68 34C68 29.5894 64.4105 26 60 26C55.5895 26 52 29.5894 52 34C52 38.4103 55.5897 42 60 42C64.4103 42 68 38.4103 68 34Z"
        fill="#E1000F"
      />
      <path
        d="M60.0366 28.7318C60.1848 28.1997 60.7362 27.8885 61.2682 28.0367C63.6078 28.6881 65.4014 30.523 65.8762 32.7982C65.989 33.3389 65.6422 33.8686 65.1016 33.9814C64.5609 34.0943 64.0312 33.7475 63.9184 33.2068C63.5998 31.6804 62.3664 30.4185 60.7318 29.9633C60.1997 29.8152 59.8885 29.2638 60.0366 28.7318Z"
        fill="#E1000F"
      />
      <path
        d="M65.9641 40.134C66.4082 39.8776 66.9673 40.0005 67.266 40.4024L67.3301 40.5L73.3301 50.8923C74.1586 52.3272 73.6669 54.162 72.2321 54.9904C70.8484 55.7892 69.0929 55.3606 68.2265 54.0424L68.134 53.8923L62.134 43.5C61.8578 43.0217 62.0217 42.4101 62.5 42.134C62.9441 41.8776 63.5032 42.0005 63.8019 42.4024L63.866 42.5L69.866 52.8923C70.1422 53.3706 70.7538 53.5345 71.2321 53.2583C71.6762 53.0019 71.8492 52.4563 71.6506 51.9967L71.5981 51.8923L65.5981 41.5C65.3219 41.0217 65.4858 40.4101 65.9641 40.134Z"
        fill="#E1000F"
      />
      <path
        d="M44.5528 30.1056C45.0468 29.8586 45.6474 30.0588 45.8944 30.5528C46.1238 31.0115 45.9675 31.5622 45.5485 31.8363L45.4472 31.8944L43.4472 32.8944C42.9532 33.1414 42.3526 32.9412 42.1056 32.4472C41.8762 31.9885 42.0325 31.4378 42.4515 31.1637L42.5528 31.1056L44.5528 30.1056Z"
        fill="#E1000F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 32C36 29.7934 37.7935 28 40 28C42.2065 28 44 29.7934 44 32C44 34.2065 42.2065 36 40 36C37.7935 36 36 34.2065 36 32ZM42 32C42 30.898 41.102 30 40 30C38.898 30 38 30.898 38 32C38 33.1019 38.8981 34 40 34C41.1019 34 42 33.1019 42 32Z"
        fill="#E1000F"
      />
      <path
        d="M59 16C59.5128 16 59.9355 16.386 59.9933 16.8834L60 17V19C60 19.5523 59.5523 20 59 20C58.4872 20 58.0645 19.614 58.0067 19.1166L58 19V18H12V54H58V49C58 48.4872 58.386 48.0645 58.8834 48.0067L59 48C59.5128 48 59.9355 48.386 59.9933 48.8834L60 49V55C60 55.5128 59.614 55.9355 59.1166 55.9933L59 56H11C10.4872 56 10.0645 55.614 10.0067 55.1166L10 55V17C10 16.4872 10.386 16.0645 10.8834 16.0067L11 16H59Z"
        fill="#000091"
      />
      <path
        d="M39 18C39.5128 18 39.9355 17.614 39.9933 17.1166L40 17V15C40 13.4023 38.7511 12.0963 37.1763 12.0051L37 12H33C31.4023 12 30.0963 13.2489 30.0051 14.8237L30 15V17C30 17.5523 30.4477 18 31 18C31.5128 18 31.9355 17.614 31.9933 17.1166L32 17V15C32 14.4872 32.386 14.0645 32.8834 14.0067L33 14H37C37.5128 14 37.9355 14.386 37.9933 14.8834L38 15V17C38 17.5523 38.4477 18 39 18Z"
        fill="#000091"
      />
      <path
        d="M51 54C51.5128 54 51.9355 54.386 51.9933 54.8834L52 55V57C52 58.5977 50.7511 59.9037 49.1763 59.9949L49 60H21C19.4023 60 18.0963 58.7511 18.0051 57.1763L18 57V55C18 54.4477 18.4477 54 19 54C19.5128 54 19.9355 54.386 19.9933 54.8834L20 55V57C20 57.5128 20.386 57.9355 20.8834 57.9933L21 58H49C49.5128 58 49.9355 57.614 49.9933 57.1166L50 57V55C50 54.4477 50.4477 54 51 54Z"
        fill="#000091"
      />
      <path
        d="M37.8 58.4C37.4686 57.9582 36.8418 57.8686 36.4 58.2C35.9922 58.5059 35.8845 59.0635 36.131 59.495L36.2 59.6L42.2 67.6C42.5314 68.0418 43.1582 68.1314 43.6 67.8C44.0078 67.4941 44.1155 66.9365 43.869 66.505L43.8 66.4L37.8 58.4Z"
        fill="#000091"
      />
      <path
        d="M23 38C23.5128 38 23.9355 38.386 23.9933 38.8834L24 39V41C24 41.5523 23.5523 42 23 42C22.4872 42 22.0645 41.614 22.0067 41.1166L22 41V40H18V48H22V45C22 44.4872 22.386 44.0645 22.8834 44.0067L23 44C23.5128 44 23.9355 44.386 23.9933 44.8834L24 45V49C24 49.5128 23.614 49.9355 23.1166 49.9933L23 50H17C16.4872 50 16.0645 49.614 16.0067 49.1166L16 49V39C16 38.4872 16.386 38.0645 16.8834 38.0067L17 38H23Z"
        fill="#000091"
      />
      <path
        d="M29 34C29.5523 34 30 34.4477 30 35C30 35.5128 29.614 35.9355 29.1166 35.9933L29 36H28V48H32V37C32 36.4872 32.386 36.0645 32.8834 36.0067L33 36C33.5128 36 33.9355 36.386 33.9933 36.8834L34 37V49C34 49.5128 33.614 49.9355 33.1166 49.9933L33 50H27C26.4872 50 26.0645 49.614 26.0067 49.1166L26 49V35C26 34.4872 26.386 34.0645 26.8834 34.0067L27 34H29Z"
        fill="#000091"
      />
      <path
        d="M39 42C39.5523 42 40 42.4477 40 43C40 43.5128 39.614 43.9355 39.1166 43.9933L39 44H38V48H42V43C42 42.4872 42.386 42.0645 42.8834 42.0067L43 42C43.5128 42 43.9355 42.386 43.9933 42.8834L44 43V49C44 49.5128 43.614 49.9355 43.1166 49.9933L43 50H37C36.4872 50 36.0645 49.614 36.0067 49.1166L36 49V43C36 42.4872 36.386 42.0645 36.8834 42.0067L37 42H39Z"
        fill="#000091"
      />
      <path
        d="M47 40C47.5128 40 47.9355 40.386 47.9933 40.8834L48 41V48H52V47C52 46.4872 52.386 46.0645 52.8834 46.0067L53 46C53.5128 46 53.9355 46.386 53.9933 46.8834L54 47V49C54 49.5128 53.614 49.9355 53.1166 49.9933L53 50H47C46.4872 50 46.0645 49.614 46.0067 49.1166L46 49V41C46 40.4477 46.4477 40 47 40Z"
        fill="#000091"
      />
      <path
        d="M32.2 58.4C32.5314 57.9582 33.1582 57.8686 33.6 58.2C34.0078 58.5059 34.1155 59.0635 33.869 59.495L33.8 59.6L27.8 67.6C27.4686 68.0418 26.8418 68.1314 26.4 67.8C25.9922 67.4941 25.8845 66.9365 26.131 66.505L26.2 66.4L32.2 58.4Z"
        fill="#000091"
      />
    </Icon>
  );
}
