import { Icon } from "@chakra-ui/react";
import React from "react";

export function AccountUnfill(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 20.5C4.977 20.5 0.5 16.023 0.5 10.5C0.5 4.977 4.977 0.5 10.5 0.5C16.023 0.5 20.5 4.977 20.5 10.5C20.5 16.023 16.023 20.5 10.5 20.5ZM5.513 16.756C6.92855 17.8877 8.68768 18.5029 10.5 18.5C12.47 18.5 14.273 17.788 15.667 16.608C14.3512 15.2579 12.5452 14.4976 10.66 14.5C8.70405 14.4977 6.83686 15.3162 5.513 16.756ZM4.116 15.32C5.81472 13.5172 8.18298 12.4967 10.66 12.5C13.0466 12.4969 15.3361 13.4448 17.022 15.134C19.3982 11.7897 18.8594 7.1899 15.7747 4.4852C12.69 1.7805 8.05929 1.8476 5.05424 4.64053C2.04919 7.43347 1.64392 12.0469 4.116 15.321V15.32ZM10.5 11.5C8.29086 11.5 6.5 9.70914 6.5 7.5C6.5 5.29086 8.29086 3.5 10.5 3.5C12.7091 3.5 14.5 5.29086 14.5 7.5C14.5 9.70914 12.7091 11.5 10.5 11.5ZM10.5 9.5C11.6046 9.5 12.5 8.60457 12.5 7.5C12.5 6.39543 11.6046 5.5 10.5 5.5C9.39543 5.5 8.5 6.39543 8.5 7.5C8.5 8.60457 9.39543 9.5 10.5 9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
