import { Icon } from "@chakra-ui/react";
import React from "react";

export function DocumentFile(props) {
  return (
    <Icon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5 31C37.2243 31 37 30.7757 37 30.5C37 30.2243 37.2243 30 37.5 30C37.7757 30 38 30.2243 38 30.5C38 30.7757 37.7757 31 37.5 31Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 2C27.2243 2 27 1.7757 27 1.5C27 1.22428 27.2243 1 27.5 1C27.7757 1 28 1.22428 28 1.5C28 1.7757 27.7757 2 27.5 2Z"
        fill="#CACAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 27C1.2243 27 1 26.7757 1 26.5C1 26.2243 1.2243 26 1.5 26C1.7757 26 2 26.2243 2 26.5C2 26.7757 1.7757 27 1.5 27Z"
        fill="#CACAFB"
      />
      <path
        d="M20.5 9C20.7761 9 21 9.22386 21 9.5C21 9.75642 20.807 9.96775 20.5583 9.99664L20.5 10H12.5C12.2239 10 12 9.77614 12 9.5C12 9.24358 12.193 9.03225 12.4417 9.00336L12.5 9H20.5Z"
        fill="#E1000F"
      />
      <path
        d="M25.5 12C25.7761 12 26 12.2239 26 12.5C26 12.7564 25.807 12.9678 25.5583 12.9966L25.5 13H12.5C12.2239 13 12 12.7761 12 12.5C12 12.2436 12.193 12.0322 12.4417 12.0034L12.5 12H25.5Z"
        fill="#E1000F"
      />
      <path
        d="M23 15.5C23 15.2239 22.7761 15 22.5 15H12.5L12.4417 15.0034C12.193 15.0322 12 15.2436 12 15.5C12 15.7761 12.2239 16 12.5 16H22.5L22.5583 15.9966C22.807 15.9678 23 15.7564 23 15.5Z"
        fill="#E1000F"
      />
      <path
        d="M25.5 18C25.7761 18 26 18.2239 26 18.5C26 18.7564 25.807 18.9678 25.5583 18.9966L25.5 19H12.5C12.2239 19 12 18.7761 12 18.5C12 18.2436 12.193 18.0322 12.4417 18.0034L12.5 18H25.5Z"
        fill="#000091"
      />
      <path
        d="M26 24.5C26 24.2239 25.7761 24 25.5 24H12.5L12.4417 24.0034C12.193 24.0322 12 24.2436 12 24.5C12 24.7761 12.2239 25 12.5 25H25.5L25.5583 24.9966C25.807 24.9678 26 24.7564 26 24.5Z"
        fill="#000091"
      />
      <path
        d="M23 21.5C23 21.2239 22.7761 21 22.5 21H12.5L12.4417 21.0034C12.193 21.0322 12 21.2436 12 21.5C12 21.7761 12.2239 22 12.5 22H22.5L22.5583 21.9966C22.807 21.9678 23 21.7564 23 21.5Z"
        fill="#000091"
      />
      <path
        d="M22.5 30C22.7761 30 23 30.2239 23 30.5C23 30.7564 22.807 30.9678 22.5583 30.9966L22.5 31H12.5C12.2239 31 12 30.7761 12 30.5C12 30.2436 12.193 30.0322 12.4417 30.0034L12.5 30H22.5Z"
        fill="#000091"
      />
      <path
        d="M20 27.5C20 27.2239 19.7761 27 19.5 27H12.5L12.4417 27.0034C12.193 27.0322 12 27.2436 12 27.5C12 27.7761 12.2239 28 12.5 28H19.5L19.5583 27.9966C19.807 27.9678 20 27.7564 20 27.5Z"
        fill="#000091"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 6H28.5C28.7761 6 29 6.22386 29 6.5V33.5C29 33.7761 28.7761 34 28.5 34H9.5C9.22386 34 9 33.7761 9 33.5V6.5C9 6.22386 9.22386 6 9.5 6ZM28 33V7H10V33H28Z"
        fill="#000091"
      />
      <path
        d="M30.5 10C30.7564 10 30.9678 10.193 30.9966 10.4417L31 10.5V35.5C31 35.7564 30.807 35.9678 30.5583 35.9966L30.5 36H13.5C13.2239 36 13 35.7761 13 35.5C13 35.2436 13.193 35.0322 13.4417 35.0034L13.5 35H30V10.5C30 10.2436 30.193 10.0322 30.4417 10.0034L30.5 10Z"
        fill="#000091"
      />
    </Icon>
  );
}
